import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout, aboutLogo, aboutParallax } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" alt="Sushi wara About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto" w="100%" maxWidth="1400px">
          <CFImage src={about} w="100%" alt="Promotions" />
          <CFView
            column
            center
            image={`url(${aboutParallax}) bottom/ cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
            h="500px"
          >
            <CFImage src={aboutLogo} m="0 auto" w="60%" alt="About Logo" />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
